import React from "react"

import { Image } from "react-bootstrap"
import BasicLayout from "../../layouts/basic-layout"


const Organization = () => (
  <BasicLayout>
    <Image src="http://cdn.jerryshi.com/gtkj/20200103161921.svg" fluid />
  </BasicLayout>
)

export default Organization
